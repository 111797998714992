import React, { useContext, useRef } from 'react'
import { MenuContext } from '../../../context/MenuContext'
import SidebarItem from '../SidebarItem'

import { gsap } from 'gsap'
import { useEffect } from 'react'
import getModelLink from '../../../utils/getModelLink'

const parseChildLink = ({ slug, pageTitle: text, locale, model }) => ({
    text,
    link: getModelLink(slug, locale, model?.name),
})

const parseNavLink = ({ label: _label, link, dropdown, model }) => ({
    text: _label || link?.pageTitle || link?.heading,
    link: link?.slug
        ? getModelLink(link.slug, link.locale, model?.name)
        : false,
    children: dropdown?.map((dropdownLink) => parseChildLink(dropdownLink)),
})

const createSideBar = (links) => {
    const newArr = []

    links.map((link, index) => {
        if (link.children.length) {
            if (link.children)
                link.children.map((child, index) => {
                    return newArr.push({
                        text: child.text,
                        link: child.link,
                    })
                })
        }

        // only push links with a link not just a label
        if (link.link)
            return newArr.push({
                text: link.text,
                link: link.link,
            })

        return null
    })

    return newArr
}

const Sidebar = ({ links }) => {
    const { menuOpen } = useContext(MenuContext)

    const menuContainer = useRef()
    const listContainer = useRef()

    const nav = (links || []).map((link) => parseNavLink(link))

    const sideNav = createSideBar(nav)

    const tl = useRef(
        gsap
            .timeline({ defaults: { ease: 'power2.inOut' } })
            .addLabel('start', 0)
            .addLabel('navShow', 0.1)
            .reverse()
    )

    useEffect(() => {
        tl.current.reversed(!menuOpen)
    }, [menuOpen])

    useEffect(() => {
        tl.current.to(
            [menuContainer.current],
            {
                left: '0%',
                duration: 0.2,
            },
            'start'
        )

        tl.current.to(
            [listContainer.current.children],
            {
                opacity: 1,
                stagger: 0.1,
            },
            'navShow'
        )
    }, [])

    return (
        <div
            className={`fixed w-full h-full bg-darks5 top-0 left-[100%] block`}
            ref={menuContainer}
        >
            <div className="flex flex-col items-center justify-center h-full">
                <nav className="text-center uppercase font-display text-4xl text-white">
                    <ul ref={listContainer}>
                        {sideNav.map((item, index) => {
                            return <SidebarItem key={index} {...item} />
                        })}
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Sidebar
