import React from 'react';
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql, Script } from 'gatsby';

import Refinery from '@assets/fonts/refinery/Refinery-25Medium.woff2';
import Rajdhani from '@assets/fonts/rajdhani/Rajdhani-Medium.woff2';

const Seo = ({ description, lang, meta, title }) => {
    const { site } = useStaticQuery(graphql`
        query SiteMetadata {
            site {
                siteMetadata {
                    title
                    siteUrl
                    description
                }
            }
        }
    `);

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`${title} | ${site.siteMetadata.title}`}
            meta={meta}
        >
            {/* <!-- Start of thecyclef rontier Zendesk Widget script --> */}
            <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=028d7508-ae8a-4688-a4c3-e795236d74e3" />
            {/* <!-- End of thecyclefrontier Zendesk Widget script --> */}
            <link rel="preload" href={Refinery} type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" href={Rajdhani} type="font/woff2" crossOrigin="anonymous" />
        </Helmet>
    )
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``
};

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default Seo;