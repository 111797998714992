import { Link } from 'gatsby'
import React, { Fragment, useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/all'

import { FaChevronDown } from 'react-icons/fa'

gsap.registerPlugin(CSSPlugin)

const NavItem = ({ text, link, children, last = false }) => {
    const megaContainer = useRef()

    useEffect(() => {
        megaContainer.current = gsap.fromTo(
            megaContainer.current,
            {
                duration: 0.2,
                paused: true,
                display: 'none',
                opacity: 0,
                y: '-100%',
            },
            {
                duration: 0.2,
                paused: true,
                display: 'block',
                opacity: 1,
                y: '0%',
            }
        )
    }, [])

    const handleDropdown = () => {
        megaContainer.current.play()
    }

    const handleDropdownClose = () => {
        megaContainer.current.reverse()
    }

    return (
        <li
            className="group h-full relative flex items-center justify-center flex-col"
            onMouseOver={handleDropdown}
            onMouseOut={handleDropdownClose}
            onFocus={handleDropdown}
            onBlur={handleDropdownClose}
        >
            {link ? (
                <Link
                    to={link}
                    className={`group-hover:text-blue transition-colors lg:text-lg cursor-pointer flex flex-row flex-nowrap items-center justify-center h-full px-8 gap-3 border-yellow z-50`}
                    activeClassName="activeNav"
                >
                    {!last ? (
                        <span className="block w-px h-7 bg-yellow absolute top-1/2 right-0 transform -translate-y-1/2" />
                    ) : null}
                    {text}

                    {children && children.length ? (
                        <FaChevronDown className="group-hover:transform group-hover:rotate-180 transition-all" />
                    ) : null}
                </Link>
            ) : (
                <span className={`group-hover:text-blue transition-colors lg:text-lg cursor-pointer flex flex-row flex-nowrap items-center justify-center h-full px-8 gap-3 border-yellow z-50`}>
                    {!last ? (
                        <span className="block w-px h-7 bg-yellow absolute top-1/2 right-0 transform -translate-y-1/2" />
                    ) : null}
                    {text}

                    {children && children.length ? (
                        <FaChevronDown className="group-hover:transform group-hover:rotate-180 transition-all" />
                    ) : null}
                </span>
            )}
            {children && children.length ? (
                <Fragment>
                    <div
                        className="absolute transform translate-y-0 top-full w-max pt-2 max-w-[550px] 2xl:max-w-none"
                        ref={megaContainer}
                    >
                        <ul className="flex flex-row items-center justify-center flex-wrap text-center w-full text-md gap-4 bg-darks2 bg-opacity-60 text-offwhite py-3 px-6 backdrop-filter backdrop-blur-[1.5px] z-40">
                            {Object.values(children).map((link, index) => {
                                return (
                                    <li key={index}>
                                        <Link
                                            to={link.link}
                                            className="hover:text-blue transition-colors"
                                            activeClassName="activeNav"
                                        >
                                            {link.text}
                                        </Link>
                                    </li>
                                )
                            })}

                            {!last ? (
                                <li>
                                    <a
                                        href="https://rewards.thecycle.game"
                                        target="_blank"
                                        rel="nofollow noopener noreferrer"
                                        className="hover:text-blue transition-colors"
                                    >
                                        Twitch Drops
                                    </a>
                                </li>
                            ) : null}
                        </ul>
                    </div>
                </Fragment>
            ) : null}
        </li>
    )
}

export default NavItem
