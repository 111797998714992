import React from "react";
import NavItem from "../Navitem";

import getModelLink from "../../../utils/getModelLink";

const parseChildLink = ({ slug, pageTitle: text, locale, model }) => ({
  text,
  link: getModelLink(slug, locale, model?.name),
});

const parseNavLink = ({ label: _label, link, dropdown, model }) => ({
  text: _label || link?.pageTitle || link?.heading,
  link: link?.slug ? getModelLink(link.slug, link.locale, model?.name) : false,
  children: dropdown?.map((dropdownLink) => parseChildLink(dropdownLink)),
});

const Nav = ({ links }) => {
  const nav = (links || []).map((link) => parseNavLink(link));

  return (
    <nav className="hidden xl:block w-auto border-box -ml-3">
      <ul className="list-none w-auto flex flex-row items-center bg-darks3 bg-opacity-60 text-offwhite h-full font-body uppercase text-lg pl-3 backdrop-filter backdrop-blur-[1.5px]">
        {nav.map((item, index) => {
          return (
            <NavItem key={index} last={nav.length === index + 1} {...item} />
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
