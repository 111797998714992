import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import Steam from '@assets/svgs/steam.svg'
import Discord from '@assets/svgs/discord.svg'
import Facebook from '@assets/svgs/facebook.svg'
import EpicGames from '@assets/svgs/epic-games.svg'
import Twitter from '@assets/svgs/twitter.svg'
import Youtube from '@assets/svgs/youtube.svg'
import Instagram from '@assets/svgs/instagram.svg'

const Footer = ({ locale }) => {
    const { configs } = useStaticQuery(graphql`
        query FooterQuery {
            configs: allDatoCmsGlobalConfig {
                edges {
                    node {
                        locale
                        id
                        footerGameLogo {
                            gatsbyImageData(placeholder: BLURRED, width: 225)
                            alt
                        }
                        footerYagerLogo {
                            gatsbyImageData(placeholder: BLURRED, width: 175)
                            alt
                        }
                        footerLegal
                        footerIcons {
                            socialType
                            urlLink
                        }
                        footerLegalLinks {
                            ... on DatoCmsPolicy {
                                id
                                pageNiceName
                                slug
                            }
                            ... on DatoCmsPolicyCopy1 {
                                id
                                pageNiceName
                                slug
                            }
                        }
                        footerLinks {
                            ... on DatoCmsPage {
                                model {
                                    name
                                }
                                locale
                                pageSlug
                                pageTitle
                            }
                        }
                    }
                }
            }
        }
    `)

    const config = configs?.edges?.find(
        ({ node: { locale: _locale } }) => _locale === locale
    )?.node
    const gameLogo = getImage(config?.footerGameLogo)
    const yagerLogo = getImage(config?.footerYagerLogo)

    const resolveSocialIcons = (social) => {
        let socialType = JSON.parse(social).toString().replace(/\s+/g, '')

        const Socials = {
            Steam: Steam,
            Discord: Discord,
            Facebook: Facebook,
            EpicGames: EpicGames,
            Twitter: Twitter,
            Youtube: Youtube,
            Instagram: Instagram,
        }

        if (Socials[socialType]) {
            return React.createElement(Socials[socialType], {
                key: socialType,
                className: 'fill-current',
                width: 30,
                height: 30,
            })
        }
    }

    return (
        <footer className="bg-darks5 text-footer pb-12 pt-20 lg:pb-24 lg:pt-32 -mt-8">
            <div className="flex items-center flex-col lg:flex-row text-center lg:justify-between lg:text-left lg:border-t lg:border-b border-footer py-6 w-11/12 mx-auto lg:gap-8 max-w-screen-2xl">
                <div className="pb-4 lg:pb-0 border-b border-footer w-full lg:w-auto lg:border-b-0">
                    <GatsbyImage
                        image={gameLogo}
                        alt={config.footerGameLogo.alt ? config.footerGameLogo.alt : 'The Cycle: Frontier Logo'}
                        className="mx-auto mb-4 lg:mb-0"
                    />
                </div>
                <div className="font-body mt-8 lg:mt-0">
                    <p className="m-0 mb-4">{config?.footerLegal}</p>
                    <div className="flex flex-row items-center text-center lg:text-left justify-center lg:justify-start gap-4 mb-6 lg:mb-0">
                        {config?.footerLegalLinks?.map((link, i) => (
                            <Link
                                key={i}
                                to={`${locale.toLowerCase() !== ("en" || undefined) ? `/${locale.toLowerCase()}/${link.slug}` : `/${link.slug}`}`}
                                className="underline hover:text-blue transition-all"
                            >
                                {link.pageNiceName}
                            </Link>
                        ))}
                    </div>
                </div>
                <StaticImage
                    src="../../assets/images/shapes/rectangle.png"
                    objectFit="contain"
                    alt=""
                    className="!hidden lg:!block lg:w-80"
                />
                <GatsbyImage
                    image={yagerLogo}
                    alt={config.footerYagerLogo.alt ? config.footerYagerLogo.alt : 'YAGER Logo'}
                />
            </div>
            <div className="border-t lg:border-t-0 lg:border-b border-footer py-6 w-11/12 mx-auto lg:flex lg:flex-row lg:justify-between max-w-screen-2xl">
                <div className="font-display flex flex-col lg:flex-row gap-4 lg:gap-3 align-center items-center text-lg pb-4 mb-8 lg:mb-0 lg:pb-0 border-b border-footer lg:border-b-0 xl:gap-4 xl:text-xl 2xl:text-2xl 3xl:text-3xl">
                    <hr className="h-1 w-6 xl:w-8 2xl:w-10 hidden lg:block " />
                    {config?.footerLinks?.map((link, i) => (
                        <Link
                            to={`${locale.toLowerCase() !== ("en" || undefined) ? `/${locale.toLowerCase()}/${link.pageSlug}` : `/${link.pageSlug}`}`}
                            key={i}
                            className="hover:underline hover:text-blue transition-all uppercase"
                        >
                            {link.pageTitle}
                        </Link>
                    ))}
                </div>
                <div className="flex flex-row items-center justify-center gap-4">
                    {config?.footerIcons?.map((icon, index) => (
                        <a
                            key={index}
                            href={icon.urlLink}
                            target="_blank"
                            rel="noopenner noreferrer"
                            className="hover:text-blue transition-all"
                        >
                            {resolveSocialIcons(icon.socialType)}
                        </a>
                    ))}
                </div>
            </div>
        </footer>
    )
}

export default Footer
