import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

import CookieBanner from '../../components/CookieBanner'

const Layout = ({ children, locale }) => {
    return (
        <main>
            <Header {...{ locale }} />
            {children}
            <CookieBanner />
            <Footer {...{ locale }} />
        </main>
    )
}

export default Layout
