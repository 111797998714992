import React, { useEffect, useRef } from "react";
import { useMenuState } from "../../../context";

import { gsap } from 'gsap';

const Hamburger = () => {
  const { menuOpen, setMenuOpen } = useMenuState(false);

  const tl = useRef(gsap.timeline({ defaults: { ease: "power2.inOut" } }).addLabel('start', 0).addLabel('collapse', 0.2).addLabel('cross', 0.3).reverse());
  const hamburger = useRef();

  const handleHamToggle = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    tl.current.reversed(!menuOpen);
  }, [menuOpen])

  useEffect(() => {
    tl.current.to([hamburger.current.children], {
      left: 0,
      duration: 0.2,
    }, "start");

    tl.current.to([hamburger.current.children], {
      top: 6,
      duration: 0.1
    }, "collapse");

    tl.current.to([hamburger.current.children[1]], {
      opacity: 0,
      duration: 0.01,
    }, "collapse")

    tl.current.to([hamburger.current.children[0]], {
      rotateZ: "45deg",
      duration: 0.2,
    }, "cross");

    tl.current.to([hamburger.current.children[2]], {
      rotateZ: "-45deg",
      duration: 0.2
    }, "cross");
  }, []);

  // Run update whenever location changes to close down menu with useEffect.

  return (
    <div className="block xl:hidden">
      <div className="border border-offwhite border-opacity-50 px-2 py-4 relative cursor-pointer group transition-all w-12 h-12"
        onClick={handleHamToggle}
        onKeyPress={(e) => (e.key === "Enter") && handleHamToggle()}
        role="button"
        tabIndex={0}
      >
        <div className="z-50 relative flex flex-col items-center justify-center w-full h-full cursor-pointer" ref={hamburger}>
          <span className="block w-6 h-[2px] bg-white mb-1 absolute top-0" />
          <span className="block w-6 h-[2px] bg-white mb-1 absolute top-1.5 left-1.5 group-hover:bg-yellow" />
          <span className="block w-6 h-[2px] bg-white absolute top-3" />
        </div>

        <div className="absolute w-full h-full top-0 left-0 z-[-10]">
          <div className="relative w-full h-full z-[-10]">
            <span className="block w-1 h-1 rounded bg-white -top-0.5 -left-0.5 absolute group-hover:bg-blue transition-all" />
            <span className="block w-1 h-1 rounded bg-white -bottom-0.5 -right-0.5 absolute group-hover:bg-blue transition-all" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hamburger;
