import React, { useRef, useEffect, useState } from 'react'

import { gsap } from 'gsap'

import { CSSPlugin } from 'gsap/CSSPlugin'
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'
import { Link } from 'gatsby'

gsap.registerPlugin(CSSPlugin, CSSRulePlugin)

const WhiteButton = ({ text, click, link, isExternal, classes, type, keypress, role }) => {
    const [hovered, setHovered] = useState(false)
    const buttonRef = useRef(null)
    const grayBarRef = useRef(null)
    const btnSwipe = useRef(null)
    const orangeBarRef = useRef(null)
    const orangeLine = useRef(null)
    const orangeSquares = useRef(null)
    const leftEdge = useRef(null)
    const rightEdge = useRef(null)

    const tl = useRef(
        gsap
            .timeline()
            .addLabel('swiped', 0.2)
            .addLabel('completed', 0.5)
            .reverse()
    )

    const mouseEnter = () => setHovered(true)
    const mouseLeave = () => setHovered(false)

    useEffect(() => {
        tl.current.reversed(!hovered)
    }, [hovered])

    useEffect(() => {
        tl.current.to([btnSwipe.current], {
            left: 0,
            duration: 0.2,
        })

        tl.current.to(
            [orangeBarRef.current],
            {
                width: '85%',
                duration: 0.2,
            },
            'swiped'
        )

        tl.current.to(
            [orangeSquares.current.children],
            {
                padding: '4px',
                duration: 0.1,
                stagger: 0.05,
            },
            'swiped'
        )

        tl.current.to(
            [orangeLine.current],
            {
                height: '32px',
                duration: 0.1,
            },
            'swiped'
        )

        tl.current.to(
            [leftEdge.current],
            {
                borderTopWidth: '2px',
                borderRightWidth: '2px',
                width: '16px',
                height: '16px',
                duration: 0.1,
            },
            'completed'
        )

        tl.current.to(
            [rightEdge.current],
            {
                borderBottomWidth: '2px',
                borderLeftWidth: '2px',
                width: '16px',
                height: '16px',
                duration: 0.1,
            },
            'completed'
        )
    }, [])

    const renderLink = (isExternal, link) => {
        if (!isExternal && link) {
            return (
                <Link to={`${link}`} className="z-50">
                    <div className="py-4 px-14 z-50">
                        <p
                            className={`text-darks5 uppercase z-50 font-body text-xl tracking-wider`}
                        >
                            {text}
                        </p>
                    </div>
                </Link>
            )
        } else if (isExternal && link) {
            return (
                <a
                    href={link}
                    className="z-50"
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className="py-4 px-14 z-50">
                        <p
                            className={`text-darks5 uppercase z-50 font-body text-xl tracking-wider`}
                        >
                            {text}
                        </p>
                    </div>
                </a>
            )
        } else if (type === "submit") {
            return (
                <button className="py-4 px-14 z-50 text-darks5 uppercase font-body text-xl tracking-wider" type={type}>
                    {text}
                </button>
            )
        } else {
            return (
                <div className="py-4 px-14 z-50">
                    <p
                        className={`text-darks5 uppercase z-50 font-body text-xl tracking-wider whitespace-nowrap`}
                    >
                        {text}
                    </p>
                </div>
            )
        }
    }

    return (
        <div
            ref={buttonRef}
            className={`cursor-pointer relative w-fit whitespace-nowrap ${classes}`}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            onClick={click ? () => click() : null}
            onKeyPress={keypress ? (e) => (e.key === "Enter") && keypress(e) : null}
            role="button"
            tabIndex={0}
        >
            <div className="button-yellow-bg bg-white flex items-center justify-center clipped-input relative">
                <span
                    className="block w-[120%] h-full bg-yellow z-10 absolute top-0 left-[-150%] clipped-btn-swipe"
                    ref={btnSwipe}
                />
                {renderLink(isExternal, link)}
                <div
                    className="top-bar bg-gray absolute top-0 left-0 w-[85%] h-[6px]"
                    ref={grayBarRef}
                />
                <div
                    className="bg-orange absolute top-0 left-0 w-[0%] z-20 h-[6px]"
                    ref={orangeBarRef}
                />

                <div className="squares absolute top-[40%] left-3">
                    <div className="relative grid grid-cols-2">
                        <div className="bg-gray p-[4px]" />
                        <div className="transparent p-[4px]" />
                        <div className="transparent p-[4px]" />
                        <div className="bg-gray p-[4px]" />
                        <div className="bg-gray p-[4px]" />
                        <div className="transparent p-[4px]" />
                    </div>
                </div>

                <div className="squares absolute top-[40%] z-20 left-3">
                    <div
                        className="relative grid grid-cols-2"
                        ref={orangeSquares}
                    >
                        <div className="bg-orange p-[0px]" />
                        <div className="transparent p-[0px]" />
                        <div className="transparent p-[0px]" />
                        <div className="bg-orange p-[0px]" />
                        <div className="bg-orange p-[0px]" />
                        <div className="transparent p-[0px]" />
                    </div>
                </div>

                <div className="bg-gray w-[1px] h-8 right-3 top-3 absolute" />
                <div
                    className="bg-orange w-[1px] h-0 right-3 top-3 absolute z-20"
                    ref={orangeLine}
                />
            </div>

            <div
                className="border-t-0 border-r-0 border-gray absolute -top-2 -right-2"
                ref={leftEdge}
            />
            <div
                className="border-b-0 border-l-0 border-gray absolute -bottom-2 -left-2"
                ref={rightEdge}
            />
        </div>
    )
}

export default WhiteButton
