import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import Hamburger from "./Hamburger";
import Nav from "./Nav";

import Sidebar from "./Sidebar";
import { useMenuState } from "../../context";
import WishlistDropdown from "./WishlistDropdown";
import LocaleSwitcher from "./LocaleSwitcher";

const Header = ({ location, locale }) => {
  const { configs } = useStaticQuery(graphql`
    query HeaderQuery {
      configs: allDatoCmsGlobalConfig {
        nodes {
          id
          locale
          headerLogo {
            gatsbyImageData(placeholder: BLURRED, width: 150)
            alt
          }
          wishlistButtonText
          headerLinks {
            label
            link {
              ... on DatoCmsBlog {
                model {
                  name
                }
                locale
                slug
                heading
              }
              ... on DatoCmsPolicy {
                model {
                  name
                }
                locale
                slug
                pageTitle
              }
              ... on DatoCmsPage {
                model {
                  name
                }
                locale
                slug: pageSlug
                pageTitle
              }
            }
            dropdown {
              ... on DatoCmsBlog {
                model {
                  name
                }
                locale
                slug
                heading
              }
              ... on DatoCmsPolicy {
                model {
                  name
                }
                locale
                slug
                pageTitle
              }
              ... on DatoCmsPage {
                model {
                  name
                }
                locale
                slug: pageSlug
                pageTitle
              }
            }
          }
        }
      }
    }
  `);

  const { setMenuOpen } = useMenuState();
  const [wishlistHover, setWishlistHover] = useState(false);

  useEffect(() => {
    setMenuOpen(false);
  }, [location, setMenuOpen]);

  const handleWishlist = (trigger) => {
    setWishlistHover(trigger);
  };

  const config = configs?.nodes?.find(
    ({ locale: _locale }) => _locale === locale
  );
  const headerLogo = getImage(config?.headerLogo);

  return (
    <header className="fixed top-4 z-40 w-full">
      <div className="w-11/12 m-auto flex flex-row justify-between items-start max-w-screen-2xl">
        <div className="flex flex-row justify-between xl:justify-start w-full xl:w-auto">
          <div className="p-2 px-6 xl:py-3 lg:px-8 bg-darks5 clipped-input z-50">
            <Link
              to={`${
                locale.toLowerCase() !== ("en" || undefined)
                  ? `/${locale.toLowerCase()}`
                  : `/`
              }`}
            >
              <GatsbyImage
                image={headerLogo}
                alt={
                  config?.headerLogo?.alt
                    ? config.headerLogo.alt
                    : "The Cycle Frontier Logo"
                }
                className="bg-darks5 clipped-input"
              />
            </Link>
          </div>
          <Nav links={config?.headerLinks} />
          <div className="xl:hidden flex flex-row flex-nowrap items-center justify-center gap-6">
            <LocaleSwitcher />
            <Hamburger />
          </div>
        </div>
        <div className="hidden xl:flex items-center justify-center gap-10">
          <LocaleSwitcher />
          <div
            className="relative hidden xl:flex items-center justify-center flex-col w-[16rem] lg:w-[16rem] xl:w-[16rem] cursor-pointer"
            onMouseOver={() => handleWishlist(true)}
            onMouseOut={() => handleWishlist(false)}
            onFocus={() => handleWishlist(true)}
            onBlur={() => handleWishlist(false)}
            role="tablist"
            tabIndex={0}
          >
            <div className="relative uppercase flex items-center justify-center z-20">
              <span className="absolute text-xl font-body text-darks4 tracking-wider z-10">
                {config.wishlistButtonText}
              </span>
              <StaticImage
                src="../../assets/svgs/main_btn.svg"
                objectFit="fill"
                className="h-[65px] w-auto z-0 px-2"
                alt=""
              />
            </div>
            <WishlistDropdown show={wishlistHover} />
          </div>
        </div>
      </div>
      <Sidebar links={config?.headerLinks} />
    </header>
  );
};

export default Header;
