export default function getModelLink(slug, _locale, type = 'page') {
    const defaultLang = 'en'
    const locale = _locale.toLowerCase()

    switch (type) {
        case 'page':
            return locale === defaultLang ? `/${slug}` : `/${locale}/${slug}`
        case 'blog':
            return locale === defaultLang
                ? `/news-and-media/${slug}`
                : `/news-and-media/${locale}/${slug}`
        default:
            return locale === defaultLang ? `/${slug}` : `/${locale}/${slug}`
    }
}
