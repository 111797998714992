import { graphql, useStaticQuery, Link } from "gatsby";
import lottie from "lottie-web";
import React, { useRef, useState, useEffect } from "react";
import { GiWorld, GiExplodingPlanet } from "react-icons/gi";
import CustomScrollbar from "../../CustomScrollbar";

import * as animationData from "../../../assets/lotties/onClick.json";

const LocaleSwitcher = () => {
  const {
    datoCmsSite: { locales },
  } = useStaticQuery(graphql`
    query LocaleQuery {
      datoCmsSite {
        locales
      }
    }
  `);

  const globeRef = useRef(null);
  const didMount = useRef(false);
  const [active, setActive] = useState(false);
  let pathname = "/";

  if (typeof window !== "undefined") {
    pathname = window.location.pathname
      .split("/")
      .filter((n) => !locales.includes(n))
      .join("/");
  }
  useEffect(() => {
    lottie.loadAnimation({
      container: globeRef.current,
      animationData: animationData,
      renderer: "svg",
      loop: false,
      autoplay: false,
      direction: 1,
    });
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      // This will ensure the animation doesn't play on page load
      return (didMount.current = true);
    }
    if (active) {
      lottie.setDirection(1);
      return lottie.play();
    } else {
      lottie.setDirection(-1);
      return lottie.play();
    }
  }, [active]);

  const handleLocaleMenu = () => {
    setActive(!active);
  };

  return (
    <div className="locale-switcher relative">
      <div
        className="flex items-center justify-center bg-darks3 bg-opacity-60 backdrop-filter backdrop-blur-[1.5px] p-1 lg:p-2 cursor-pointer clipped-locale"
        onClick={() => handleLocaleMenu()}
      >
        <div ref={globeRef} className="w-10 h-10 lg:w-12 lg:h-12" />
        {/* <GiWorld onClick={handleLocaleMenu} className={`${active ? 'hidden' : 'block'} text-white w-10 h-10 cursor-pointer transition-all`} />
                <GiExplodingPlanet onClick={handleLocaleMenu} className={`${active ? 'block' : 'hidden'} text-white w-10 h-10 cursor-pointer transition-all`} /> */}
      </div>

      <div
        className={`${active ? "block" : "hidden"
          } bg-darks5 py-4 pl-4 pr-2 absolute -bottom-56 -left-14 text-white shadow-2xl w-36`}
      >
        <CustomScrollbar
          style={{
            width: "100%",
            height: 165,
          }}
        >
          <div className="flex flex-col gap-2 flex-wrap w-28">
            {locales.map((locale, index) => {
              return (
                <Link
                  to={`${locale === "en"
                    ? pathname
                    : `/${locale.toLowerCase()}${pathname}`
                    }`}
                  key={index}
                  className="hover:text-blue uppercase font-body text-xl"
                >
                  {locale.toLowerCase()}
                </Link>
              );
            })}
          </div>
        </CustomScrollbar>
      </div>
    </div>
  );
};

export default LocaleSwitcher;
