import React, { useEffect, useRef } from 'react'
import Steam from '../../../assets/svgs/steam.svg'
import EpicGames from '../../../assets/svgs/epic-games.svg'

import { gsap } from 'gsap'
import CSSPlugin from 'gsap/CSSPlugin'
import { graphql, useStaticQuery } from 'gatsby'

gsap.registerPlugin(CSSPlugin)

const WishlistDropdown = ({ show }) => {
    const container = useRef()

    const { stores } = useStaticQuery(graphql`
        query WishlistQuery {
            stores: allDatoCmsGlobalConfig(filter: { locale: { eq: "en" } }) {
                edges {
                    node {
                        epicStoreUrl
                        steamUrl
                    }
                }
            }
        }
    `)

    useEffect(() => {
        container.current = gsap.fromTo(
            container.current,
            {
                duration: 0.2,
                opacity: 0,
                paused: true,
                y: '20%',
            },
            {
                duration: 0.2,
                opacity: 1,
                y: '50%',
            }
        )
    }, [])

    useEffect(() => {
        if (show) {
            container.current.play()
        } else {
            container.current.reverse()
        }
    }, [show])

    return (
        <div
            className={`bg-darks3 bg-opacity-50 w-full backdrop-filter backdrop-blur-[1.5px] pt-7 -mt-7 absolute top-0`}
            ref={container}
        >
            <div className="flex flex-col gap-2 text-gray">
                <a
                    href={stores.edges[0].node.steamUrl}
                    rel="noreferrer"
                    target="_blank"
                >
                    <div className="flex flex-row items-center justify-between p-3 group-hover text-offwhite hover:bg-offwhite hover:text-darks3 transition-all font-body ">
                        <p>Steam</p>
                        <Steam className="max-w-[25px] w-[25px] h-[25px] fill-current" />
                    </div>
                </a>

                <a
                    href={stores.edges[0].node.epicStoreUrl}
                    rel="noreferrer"
                    target="_blank"
                >
                    <div className="flex flex-row items-center justify-between p-3 group-hover text-offwhite hover:bg-offwhite hover:text-darks3 transition-all font-body ">
                        <p>Epic Games</p>
                        <EpicGames className="max-w-[25px] w-[25px] h-[25px] fill-current" />
                    </div>
                </a>
            </div>
        </div>
    )
}

export default WishlistDropdown
