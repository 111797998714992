import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import Cookies from 'js-cookie'

import YellowButton from '../Buttons/YellowButton'
import WhiteButton from '../Buttons/WhiteButton'

import CookieSettings from './Settings'

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false)
    const [showSettings, setShowSettings] = useState(false)

    const location = useLocation()

    const getGDPR = Cookies.get('cycle-gdpr-responded');

    const initTracking = useCallback(() => {
        initializeAndTrack(location)
    }, [location])

    useEffect(() => {
        setShowBanner(Cookies.get('cycle-gdpr-responded') !== 'true')
    }, [])

    useEffect(() => {
        if (Cookies.get('gatsby-gdpr-matomo-analytics') === 'true' && typeof window !== 'undefined') {
            window._paq.push(['setConsentGiven']);
            window._paq.push(['setCookieConsentGiven']);
        }
    }, [])

    useEffect(() => {
        initTracking()
    }, [getGDPR, initTracking])

    const handleAccept = () => {
        Cookies.set('gatsby-gdpr-matomo-analytics', true, { expires: 365 });
        if (typeof window !== 'undefined') {
            window._paq.push(['setConsentGiven']);
            window._paq.push(['setCookieConsentGiven']);
        }
        handleCloseAll()
    }

    const handleDecline = () => {
        Cookies.remove('gatsby-gdpr-matomo-analytics')
        handleCloseAll()
    }

    const handleCloseAll = () => {
        setShowSettings(false)
        setShowBanner(false)

        Cookies.set('cycle-gdpr-responded', true, { expires: 365 })
    }

    // May turn this into a PORTAL
    return (
        <>
            <div
                className={`clipped-box ${showBanner && !showSettings ? 'block' : 'hidden'
                    } text-center md:text-left bg-gray max-w-[600px] p-8 fixed bottom-4 sm:bottom-8 right-4 sm:left-[50%] sm:right-0 sm:-translate-x-1/2 md:translate-x-0 md:right-8 md:left-auto shadow-xl z-[100] w-11/12 font-body`}
            >
                <p className="mb-4">
                    Our site uses cookies and similar technologies. Some are
                    essential and some improve the experience. Please click
                    ‘accept’ to accept all cookies. Please click ‘decline’ to
                    keep all cookies off except those that are necessary.
                </p>

                <div className="flex flex-col md:flex-row items-center">
                    <div className="flex flex-col sm:flex-row sm:items-center">
                        <YellowButton
                            click={() => handleAccept()}
                            keypress={() => handleAccept()}
                            text="Accept"
                        />
                        <WhiteButton
                            click={() => handleDecline()}
                            keypress={() => handleDecline()}
                            text="Decline"
                            classes="mt-2 sm:mt-0 sm:ml-2"
                        />
                    </div>
                    <button
                        className="inline-block ml-2 mt-4 md:mt-0 px-4 text-lg hover:underline text-primary cursor-pointer"
                        onClick={() => setShowSettings(true)}
                        onKeyPress={(e) => (e.key === "Enter") && setShowSettings(true)}
                        tabIndex={0}
                    >
                        Configure
                    </button>
                </div>
            </div>

            {showSettings && (
                <CookieSettings
                    {...{
                        handleAccept,
                        handleDecline,
                        initTracking,
                        handleCloseAll,
                    }}
                />
            )}
        </>
    )
}

export default CookieBanner
