import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";

const CustomScrollbar = (props) => {
    return (
        <Scrollbars
            renderTrackVertical={({ style, ...props }) => (
                <div
                    {...props}
                    className="track-vertical"
                    style={{
                        ...style,
                        backgroundColor: "#121c25",
                        height: "100%",
                        right: 0,
                        width: 12,
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 4,
                        paddingRight: 4,
                    }}
                />
            )}
            renderThumbVertical={({ style, ...props }) => (
                <div
                    {...props}
                    style={{
                        ...style,
                        backgroundColor: "#79c6f2",
                        borderRadius: "0.25rem",
                        height: "1rem",
                        boxShadow: "0 0px 4px rgba(121, 198, 242, 0.8)",
                    }}
                />
            )}
            renderView={(props) => <div {...props} className="view" />}
            {...props}
            thumbSize={46}
            className="r-0 h-full"
        >
            {props.children}
        </Scrollbars>
    );
};

export default CustomScrollbar;
