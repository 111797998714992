import { Link } from "gatsby";
import React from "react";

const SidebarItem = ({ text, link, children }) => {

  return (
    <li className="flex items-center justify-center flex-col mb-2 opacity-0">
      <Link to={link} className="hover:text-blue transition-colors">
        {text}
      </Link>
    </li>
  );
};

export default SidebarItem;
