import Cookies from 'js-cookie'
import React, { useState } from 'react'

import YellowButton from '../../Buttons/YellowButton'
import WhiteButton from '../../Buttons/WhiteButton'

const Toggle = ({ active = false, toggleActive, name = "", label = "" }) => (
    <div
        onClick={typeof toggleActive === 'function' && toggleActive}
        onKeyPress={(e) => typeof toggleActive === 'function' && (e.key === "Enter") && toggleActive()}
        role="tab"
        tabIndex={0}
        className={`toggle relative cursor-pointer ${active ? 'active' : null}`}
        label={label}
        id={name}
    />
)

const CookieSettings = ({
    handleAccept,
    handleDecline,
    initTracking,
    handleCloseAll,
}) => {
    const [trackAnalytics, setTrackAnalytics] = useState(
        Cookies.get('gatsby-gdpr-matomo-analytics') === 'true'
    )

    const handleToggle = () => {
        Cookies.get('cycle-gdpr-responded', true, { expires: 365 })

        setTrackAnalytics((prevState) => {
            if (prevState) {
                Cookies.remove('gatsby-gdpr-matomo-analytics')
            } else {
                Cookies.set('gatsby-gdpr-matomo-analytics', true, {
                    expires: 365,
                });
                if (typeof window !== 'undefined') {
                    window._paq.push(['setConsentGiven']);
                    window._paq.push(['setCookieConsentGiven']);
                }
            }

            return !prevState
        })

        initTracking()
    }

    return (
        <div className="font-body w-full sm:w-96 h-full p-8 fixed top-0 left-0 bg-offwhite shadow-2xl overflow-y-scroll z-[100]">
            <h3 className="mb-4 text-3xl uppercase font-display ">
                This site uses cookies to store information on your computer.
            </h3>
            <div className="mb-4 text-sm">
                <p>
                    Some of these cookies are essential, while others help us to
                    improve your experience by providing insights into how the
                    site is being used.
                </p>
                <p className="mt-4">
                    You can find out how to manage your preferences in relation
                    to our use of cookies at Cookie Policy
                </p>
            </div>
            <div className="flex flex-col items-start justify-start">
                <YellowButton click={() => handleAccept()} text="Accept" />
                <WhiteButton
                    click={() => handleDecline()}
                    text="Decline"
                    classes="mt-4"
                />
            </div>
            <div className="pt-8 mt-8 border-t border-dashed border-grey1">
                <div className="mb-4 flex items-center justify-between">
                    <h4 className="uppercase font-display text-2xl">
                        Analytical Cookies
                    </h4>
                    <Toggle
                        active={trackAnalytics}
                        toggleActive={() => handleToggle()}
                        name="analyticalCookies"
                        label="Analytical cookies are non-essential cookies which help us
                        to improve our website by collecting and reporting
                        information on its usage. We use these cookeis so we can
                        better understand how you engage with our website, marketing
                        campaigns and products. The information we collect is
                        anonymised."
                    />
                </div>
                <p className="text-sm" htmlFor="analyticalCookies">
                    Analytical cookies are non-essential cookies which help us
                    to improve our website by collecting and reporting
                    information on its usage. We use these cookeis so we can
                    better understand how you engage with our website, marketing
                    campaigns and products. The information we collect is
                    anonymised.
                </p>
            </div>
            <WhiteButton
                click={() => handleCloseAll()}
                text="Close"
                classes="mt-4"
            />
        </div>
    )
}

export default CookieSettings
